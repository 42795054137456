import { ArrowDropDownRounded, ArrowDropUpRounded, CardGiftcard, Description, Discount, Equalizer, Groups, Help, Inventory, Payment, PieChart, QuestionAnswer, Receipt, ReceiptLong, Settings, ViewKanban } from "@mui/icons-material"
import { Collapse, Dialog, Divider, List, ListItem, ListItemIcon, ListItemText } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"
import SupportForm from "./SupportForm"
import { IS_EPN } from "../global"

// Multi-Route Arrays
const INVENTORY_ROUTES = ["/inventory/departments", "/inventory/categories", "/inventory/items", "/inventory/modifiers", "/inventory/vendors"]
const REPORT_ROUTES = ["/reports/batchSummary", "/reports/transactionSearch", "/reports/recurringBilling", "/reports/cardExpiration", "/reports/currentInventory", "/reports/salesReport", "/reports/itemsSold", "/reports/salesTax", "/reports/totalsales"]
const SETTING_ROUTES = ["/settings/general", "/settings/merchant", "/settings/usermanager", "/settings/roles", "/settings/api", "/settings/application", "/settings/devices"]

export default function ListItems({ setOpen, mid, dba }) {
  //  States
  const [inventoryOpen, setInventoryOpen] = React.useState(false)
  const [reportsOpen, setReportsOpen] = React.useState(false)
  const [settingsOpen, setSettingsOpen] = React.useState(false)
  const [page, setPage] = React.useState(window.location.pathname)
  const [support, setSupport] = React.useState(false)

  // Functions
  function isSelected(current, targets) { return targets.includes(current) }
  function getSelectedColor(current, targets) {
    return isSelected(current, targets) ? (IS_EPN ? "white" : "primary.main") : (IS_EPN ? "white" : "")
  }
  function goToPage(targetPage) {
    setPage(targetPage)
    setOpen(false)
  }

  return (
    <>
      <List disablePadding sx={{ width: 239.7 }}>
        <ListItem
          button
          component={Link}
          to="/dashboard"
          selected={isSelected(page, ["/dashboard"])}
          onClick={() => goToPage("/dashboard")}
          tabIndex={-1}
        >
          <ListItemIcon>
            <Equalizer sx={{ color: getSelectedColor(page, ["/dashboard"]) }} />
          </ListItemIcon>
          <ListItemText
            primary="Dashboard"
            primaryTypographyProps={{ color: getSelectedColor(page, ["/dashboard"]) }}
          />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/virtualterminal"
          selected={isSelected(page, ["/virtualterminal"])}
          onClick={() => goToPage("/virtualterminal")}
          tabIndex={-1}
        >
          <ListItemIcon>
            <Payment sx={{ color: getSelectedColor(page, ["/virtualterminal"]) }} />
          </ListItemIcon>
          <ListItemText
            primary="Virtual Terminal"
            primaryTypographyProps={{ color: getSelectedColor(page, ["/virtualterminal"]) }}
          />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/tickets"
          selected={isSelected(page, ["/tickets"])}
          onClick={() => goToPage("/tickets")}
          tabIndex={-1}
        >
          <ListItemIcon>
            <ReceiptLong sx={{ color: getSelectedColor(page, ["/tickets"]) }} />
          </ListItemIcon>
          <ListItemText
            primary="Tickets"
            primaryTypographyProps={{ color: getSelectedColor(page, ["/tickets"]) }}
          />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/invoices"
          selected={isSelected(page, ["/invoices", "/invoices/add"])}
          onClick={() => goToPage("/invoices")}
          tabIndex={-1}
        >
          <ListItemIcon>
            <Description sx={{ color: getSelectedColor(page, ["/invoices", "/invoices/add"]) }} />
          </ListItemIcon>
          <ListItemText
            primary="Invoices"
            primaryTypographyProps={{ color: getSelectedColor(page, ["/invoices", "/invoices/add"]) }}
          />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/plans"
          selected={isSelected(page, ["/plans"])}
          onClick={() => goToPage("/plans")}
          tabIndex={-1}
        >
          <ListItemIcon>
            <Receipt sx={{ color: getSelectedColor(page, ["/plans"]) }} />
          </ListItemIcon>
          <ListItemText
            primary="Plans"
            primaryTypographyProps={{ color: getSelectedColor(page, ["/plans"]) }}
          />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/customers"
          selected={isSelected(page, ["/customers"])}
          onClick={() => goToPage("/customers")}
          tabIndex={-1}
        >
          <ListItemIcon>
            <Groups sx={{ color: getSelectedColor(page, ["/customers"]) }} />
          </ListItemIcon>
          <ListItemText
            primary="Customers"
            primaryTypographyProps={{ color: getSelectedColor(page, ["/customers"]) }}
          />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/batchmanager"
          selected={isSelected(page, ["/batchmanager", "/transaction"])}
          onClick={() => goToPage("/batchmanager")}
          tabIndex={-1}
        >
          <ListItemIcon>
            <Inventory sx={{ color: getSelectedColor(page, ["/batchmanager", "/transaction"]) }} />
          </ListItemIcon>
          <ListItemText
            primary="Batch Manager"
            primaryTypographyProps={{ color: getSelectedColor(page, ["/batchmanager", "/transaction"]) }}
          />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/giftcards"
          selected={isSelected(page, ["/giftcards"])}
          onClick={() => goToPage("/giftcards")}
          tabIndex={-1}
        >
          <ListItemIcon>
            <CardGiftcard sx={{ color: getSelectedColor(page, ["/giftcards"]) }} />
          </ListItemIcon>
          <ListItemText
            primary="Gift Cards"
            primaryTypographyProps={{ color: getSelectedColor(page, ["/giftcards"]) }}
          />
        </ListItem>
        <ListItem
          button
          onClick={() => setInventoryOpen(!inventoryOpen)}
          selected={isSelected(page, INVENTORY_ROUTES)}
          tabIndex={-1}
        >
          <ListItemIcon>
            <ViewKanban sx={{ color: getSelectedColor(page, INVENTORY_ROUTES) }} />
          </ListItemIcon>
          <ListItemText
            primary="Inventory"
            primaryTypographyProps={{ color: getSelectedColor(page, INVENTORY_ROUTES) }}
          />
          {inventoryOpen &&
            <ArrowDropUpRounded
              fontSize="large"
              sx={{ color: getSelectedColor(page, INVENTORY_ROUTES) }}
            />}
          {!inventoryOpen &&
            <ArrowDropDownRounded
              fontSize="large"
              sx={{ color: getSelectedColor(page, INVENTORY_ROUTES) }}
            />}
        </ListItem>
        <Collapse in={inventoryOpen}>
          <ListItem
            button
            component={Link}
            to="/inventory/departments"
            selected={isSelected(page, ["/inventory/departments"])}
            onClick={() => goToPage("/inventory/departments")}
            tabIndex={-1}
          >
            <ListItemText
              inset
              primary="Departments"
              primaryTypographyProps={{ color: getSelectedColor(page, ["/inventory/departments"]) }}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/inventory/categories"
            selected={isSelected(page, ["/inventory/categories"])}
            onClick={() => goToPage("/inventory/categories")}
            tabIndex={-1}
          >
            <ListItemText
              inset
              primary="Categories"
              primaryTypographyProps={{ color: getSelectedColor(page, ["/inventory/categories"]) }}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/inventory/items"
            selected={isSelected(page, ["/inventory/items"])}
            onClick={() => goToPage("/inventory/items")}
            tabIndex={-1}
          >
            <ListItemText
              inset
              primary="Items"
              primaryTypographyProps={{ color: getSelectedColor(page, ["/inventory/items"]) }}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/inventory/modifiers"
            selected={isSelected(page, ["/inventory/modifiers"])}
            onClick={() => goToPage("/inventory/modifiers")}
            tabIndex={-1}
          >
            <ListItemText
              inset
              primary="Modifiers"
              primaryTypographyProps={{ color: getSelectedColor(page, ["/inventory/modifiers"]) }}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/inventory/vendors"
            selected={isSelected(page, ["/inventory/vendors"])}
            onClick={() => goToPage("/inventory/vendors")}
            tabIndex={-1}
          >
            <ListItemText
              inset
              primary="Vendors"
              primaryTypographyProps={{ color: getSelectedColor(page, ["/inventory/vendors"]) }}
            />
          </ListItem>
        </Collapse>
        <ListItem
          button
          component={Link}
          to="/discounts"
          selected={isSelected(page, ["/discounts"])}
          onClick={() => goToPage("/discounts")}
          tabIndex={-1}
        >
          <ListItemIcon>
            <Discount sx={{ color: getSelectedColor(page, ["/discounts"]) }} />
          </ListItemIcon>
          <ListItemText
            primary="Discounts"
            primaryTypographyProps={{ color: getSelectedColor(page, ["/discounts"]) }}
          />
        </ListItem>
        <ListItem
          button
          onClick={() => setReportsOpen(!reportsOpen)}
          tabIndex={-1}
        >
          <ListItemIcon>
            <PieChart sx={{ color: getSelectedColor(page, REPORT_ROUTES) }} />
          </ListItemIcon>
          <ListItemText
            primary="Reports"
            primaryTypographyProps={{ color: getSelectedColor(page, REPORT_ROUTES) }}
          />
          {reportsOpen &&
            <ArrowDropUpRounded
              fontSize="large"
              sx={{ color: getSelectedColor(page, REPORT_ROUTES) }}
            />}
          {!reportsOpen &&
            <ArrowDropDownRounded
              fontSize="large"
              sx={{ color: getSelectedColor(page, REPORT_ROUTES) }}
            />}
        </ListItem>
        <Collapse in={reportsOpen}>
          <ListItem
            button
            component={Link}
            to="/reports/batchSummary"
            selected={isSelected(page, ["/reports/batchSummary"])}
            onClick={() => goToPage("/reports/batchSummary")}
            tabIndex={-1}
          >
            <ListItemText
              inset
              primary="Batch Summary"
              primaryTypographyProps={{ color: getSelectedColor(page, ["/reports/batchSummary"]) }}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/reports/transactionSearch"
            selected={isSelected(page, ["/reports/transactionSearch"])}
            onClick={() => goToPage("/reports/transactionSearch")}
            tabIndex={-1}
          >
            <ListItemText
              inset
              primary="Transaction Search"
              primaryTypographyProps={{ color: getSelectedColor(page, ["/reports/transactionSearch"]) }}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/reports/recurringBilling"
            selected={isSelected(page, ["/reports/recurringBilling"])}
            onClick={() => goToPage("/reports/recurringBilling")}
            tabIndex={-1}
          >
            <ListItemText
              inset
              primary="Recurring Billing"
              primaryTypographyProps={{ color: getSelectedColor(page, ["/reports/recurringBilling"]) }}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/reports/cardExpiration"
            selected={isSelected(page, ["/reports/cardExpiration"])}
            onClick={() => goToPage("/reports/cardExpiration")}
          >
            <ListItemText
              inset
              primary="Card Expiration"
              primaryTypographyProps={{ color: getSelectedColor(page, ["/reports/cardExpiration"]) }}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/reports/currentInventory"
            selected={isSelected(page, ["/reports/currentInventory"])}
            onClick={() => goToPage("/reports/currentInventory")}
            tabIndex={-1}
          >
            <ListItemText
              inset
              primary="Current Inventory"
              primaryTypographyProps={{ color: getSelectedColor(page, ["/reports/currentInventory"]) }}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/reports/itemsSold"
            selected={isSelected(page, ["/reports/itemsSold"])}
            onClick={() => goToPage("/reports/itemsSold")}
            tabIndex={-1}
          >
            <ListItemText
              inset
              primary="Items Sold"
              primaryTypographyProps={{ color: getSelectedColor(page, ["/reports/itemsSold"]) }}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/reports/salesTax"
            selected={isSelected(page, ["/reports/salesTax"])}
            onClick={() => goToPage("/reports/salesTax")}
            tabIndex={-1}
          >
            <ListItemText
              inset
              primary="Sales Tax"
              primaryTypographyProps={{ color: getSelectedColor(page, ["/reports/salesTax"]) }}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/reports/totalsales"
            selected={isSelected(page, ["/reports/totalsales"])}
            onClick={() => goToPage("/reports/totalsales")}
            tabIndex={-1}
          >
            <ListItemText
              inset
              primary="Total Sales"
              primaryTypographyProps={{ color: getSelectedColor(page, ["/reports/totalsales"]) }}
            />
          </ListItem>
        </Collapse>
        <ListItem
          button
          onClick={() => setSettingsOpen(!settingsOpen)}
          selected={isSelected(page, SETTING_ROUTES)}
          tabIndex={-1}
        >
          <ListItemIcon>
            <Settings sx={{ color: getSelectedColor(page, SETTING_ROUTES) }} />
          </ListItemIcon>
          <ListItemText
            primary="Settings"
            primaryTypographyProps={{ color: getSelectedColor(page, SETTING_ROUTES) }}
          />
          {settingsOpen &&
            <ArrowDropUpRounded
              fontSize="large"
              sx={{ color: getSelectedColor(page, SETTING_ROUTES) }}
            />}
          {!settingsOpen &&
            <ArrowDropDownRounded
              fontSize="large"
              sx={{ color: getSelectedColor(page, SETTING_ROUTES) }}
            />}
        </ListItem>
        <Collapse in={settingsOpen}>
          <ListItem
            button
            component={Link}
            to="/settings/general"
            selected={isSelected(page, ["/settings/general"])}
            onClick={() => goToPage("/settings/general")}
            tabIndex={-1}
          >
            <ListItemText
              inset
              primary="General"
              primaryTypographyProps={{ color: getSelectedColor(page, ["/settings/general"]) }}
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/settings/merchant"
            selected={isSelected(page, ["/settings/merchant"])}
            onClick={() => goToPage("/settings/merchant")}
            tabIndex={-1}
          >
            <ListItemText
              inset
              primary="Merchant"
              primaryTypographyProps={{ color: getSelectedColor(page, ["/settings/merchant"]) }}
            />
          </ListItem>
          {sessionStorage.getItem("role") === "Admin" &&
            <ListItem
              button
              component={Link}
              to="/settings/usermanager"
              selected={isSelected(page, ["/settings/usermanager"])}
              onClick={() => goToPage("/settings/usermanager")}
              tabIndex={-1}
            >
              <ListItemText
                inset
                primary="Users"
                primaryTypographyProps={{ color: getSelectedColor(page, ["/settings/usermanager"]) }}
              />
            </ListItem>}
        </Collapse>
        <Divider sx={{ borderColor: IS_EPN ? "white" : "" }} />
        <ListItem
          button
          tabIndex={-1}
          onClick={() => {
            const pathArr = window.location.pathname.split("/")
            window.open("https://docs.reefpay.net/web/" + pathArr[1], "documentation")
          }}
        >
          <ListItemIcon>
            <Help sx={{ color: getSelectedColor(page, ["/help"]) }} />
          </ListItemIcon>
          <ListItemText primary="Help" />
        </ListItem>
        <ListItem button onClick={() => setSupport(true)}>
          <ListItemIcon>
            <QuestionAnswer sx={{ color: getSelectedColor(page, ["/contactus"]) }} />
          </ListItemIcon>
          <ListItemText primary="Contact Us" />
        </ListItem>
      </List>
      {!IS_EPN && <Divider sx={{ borderColor: IS_EPN ? "white" : "" }} />}

      {/* Contact Support Form */}
      <Dialog open={support}>
        <SupportForm setOpen={setSupport} mid={mid} dba={dba} />
      </Dialog>
    </>
  )
}