import React from "react"
import { Inbox } from "@mui/icons-material"
import { createTheme } from "@mui/material"
import { green, grey, lightBlue, orange, red } from "@mui/material/colors"
import createPalette from "@mui/material/styles/createPalette"
import { Box, Typography } from "@mui/material"

function CustomNoRowsOverlay() {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
      <Inbox sx={{ fontSize: 64, color: "info.dark" }} />
      <Typography fontWeight="bold">No Rows</Typography>
    </Box>
  )
}

export default createTheme({
  shape: { borderRadius: 8 },
  typography: { fontFamily: ["Montserrat"] },
  palette: createPalette({
    primary: { light: lightBlue[50], main: "#00a1e3", dark: "#243c93", contrastText: "white" },
    secondary: { light: lightBlue[50], main: "#00a1e3", dark: "#243c93", contrastText: "white" },
    error: { light: red[300], main: red[500], dark: red[800], contrastText: "white" },
    warning: { light: orange[300], main: orange[500], dark: orange[800], contrastText: "white" },
    info: { light: grey[100], main: grey[300], dark: grey[500], contrastText: grey[800] },
    success: { light: green[100], main: green[500], dark: green[800], contrastText: "white" },
    text: { primary: grey[800], secondary: "black", disabled: grey[600] },
    divider: grey[400],
    background: { paper: "white", default: grey[100] }
  }),
  components: {
    MuiTextField: { defaultProps: { variant: "outlined", size: "small", fullWidth: true } },
    // Issue with dialog contents being cut off. Global override prevents this issue until it is resolved internally.
    MuiDialogContent: { styleOverrides: { root: { paddingTop: `5px !important` } } },
    MuiButton: {
      defaultProps: { variant: "contained", fullWidth: true },
      styleOverrides: { root: { textTransform: "none" } }
    },
    MuiDataGrid: {
      defaultProps: {
        autoHeight: true,
        headerHeight: 40,
        disableSelectionOnClick: true,
        components: { NoRowsOverlay: CustomNoRowsOverlay },
        slots: { noRowsOverlay: CustomNoRowsOverlay },
        sx: {
          boxShadow: 2,
          "& .MuiDataGrid-columnSeparator": { visibility: "hidden" },
          "& .MuiDataGrid-columnHeaders": {
            bgcolor: "primary.dark",
            color: "primary.contrastText",
            ".MuiCheckbox-root": { color: "primary.contrastText" }
          },
          "& .MuiDataGrid-columnHeaderTitle": { fontWeight: "bold" },
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            // "> *": { borderTop: 1, borderColor: "info.dark" },
            "> :first-child": { border: 0 },
            "> :nth-of-type(2n)": { bgcolor: "primary.light" }
          },
          "& .MuiButtonBase": { bgcolor: "primary.main" },
          "& .MuiDataGrid-sortIcon": { color: "primary.contrastText" },
          // "& .MuiDataGrid-footerContainer": { borderTop: 1, borderColor: "info.dark" }
        }
      }
    },
    MuiAccordionSummary: { defaultProps: { sx: { bgcolor: "secondary.dark", color: "secondary.contrastText" } } },
    MuiSwitch: { defaultProps: { color: "secondary" } }
  }
})