import { AppBar, Box, Drawer, Fab } from "@mui/material"
import React from "react"
import { BrowserView, MobileView } from "react-device-detect"
import ListItems from "./ListItems"
import reefLogo from "../public/Reef Pay.png"
import ePNLogo from "../public/ePN.png"
import reefCapitalAdLarge from "../public/Reef Capital Ad Large.webp"
import { Menu } from "@mui/icons-material"
import { IS_EPN } from "../global"


export default function Sidebar({ mid, dba }) {
  const [open, setOpen] = React.useState(false)
  return (
    <>
      <BrowserView>
        <Drawer
          variant="permanent"
          sx={{ width: 239.7, maxWidth: 239.7, borderRightWidth: 0 }}
          PaperProps={{
            elevation: 5,
            sx: {
              bgcolor: IS_EPN ? "primary.main" : "",
              color: IS_EPN ? "primary.contrastText" : "",
              scrollbarWidth: "none",
              borderRightWidth: 0
            }
          }}>
          <AppBar position="sticky" elevation={0} sx={{ bgcolor: IS_EPN ? "primary" : "white" }}>
            <Box display="flex" justifyContent="center" padding={1}>
              <img src={IS_EPN ? ePNLogo : reefLogo} alt="" width={150} />
            </Box>
          </AppBar>
          <ListItems setOpen={setOpen} mid={mid} dba={dba} />
          {!IS_EPN &&
            <Box display="flex" alignContent="center" justifyContent="center" paddingBottom={1} paddingX={1}>
              <a href={`https://capital.reefpay.net?mid=${mid}&src=reefpay`}>
                <img src={reefCapitalAdLarge} alt="" width={216} />
              </a>
            </Box>}
        </Drawer>
      </BrowserView>
      <MobileView>
        <Fab
          color="primary"
          onClick={() => setOpen(true)}
          sx={{ position: "fixed", bottom: 16, right: 16 }}
        >
          <Menu />
        </Fab>
        <Drawer anchor="left" open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)} keepMounted={true}>
          <Box display="flex" justifyContent="center" padding={1}>
            <img src={reefLogo} alt="" width={150} />
          </Box>
          <ListItems setOpen={setOpen} mid={mid} dba={dba} />
          <Box display="flex" justifyContent="center" paddingBottom={1} paddingX={1}>
            <a href={`https://capital.reefpay.net?mid=${mid}&src=reefpay`}>
              <img src={reefCapitalAdLarge} alt="" width={216} />
            </a>
          </Box>
        </Drawer>
      </MobileView>
    </>
  )
}