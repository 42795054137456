import { Box, Button, Checkbox, FormControlLabel, Grid, Paper, TextField, Typography } from "@mui/material"
import React, { useRef } from "react"
import { deviceMargin } from "../../../themes/constants"
import CategorySales from "./CategorySales"
import DepartmentSales from "./DepartmentSales"
import DiscountTypes from "./DiscountTypes"
import Payments from "./Payments"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import Voids from "./Voids"
import Refunds from "./Refunds"
import MultiTax from "./MultiTax"
import CreditTransactions from "./CreditTransactions"

export default function TotalSales({ mid, pageSize, gatewayUsername, gatewayPassword, gatewayKey }) {
  // States
  const [start, setStart] = React.useState(new Date())
  const [end, setEnd] = React.useState(new Date())
  const [report, setReport] = React.useState({
    payments: true,
    credit: true,
    multitax: true,
    refunds: false,
    departments: true,
    categories: true,
    discounts: true,
    voids: true
  })

  // References
  const paymentRef = useRef(null)
  const creditRef = useRef(null)
  const multitaxRef = useRef(null)
  const refundRef = useRef(null)
  const departmentRef = useRef(null)
  const categoryRef = useRef(null)
  const discountRef = useRef(null)
  const voidRef = useRef(null)

  // Functions
  function fetchReports() {
    if (report.payments) paymentRef.current.fetchReport(start, end)
    if (report.credit) creditRef.current.fetchReport(start, end)
    if (report.multitax) multitaxRef.current.fetchReport(start, end)
    if (report.refunds) refundRef.current.fetchReport(start, end)
    if (report.departments) departmentRef.current.fetchReport(start, end)
    if (report.categories) categoryRef.current.fetchReport(start, end)
    if (report.discounts) discountRef.current.fetchReport(start, end)
    if (report.voids) voidRef.current.fetchReport(start, end)
  }

  function exportReports() {
    if (report.payments) paymentRef.current.exportReport()
    if (report.credit) creditRef.current.exportReport()
    if (report.multitax) multitaxRef.current.exportReport()
    if (report.refunds) refundRef.current.exportReport()
    if (report.departments) departmentRef.current.exportReport()
    if (report.categories) categoryRef.current.exportReport()
    if (report.discounts) discountRef.current.exportReport()
    if (report.voids) voidRef.current.exportReport()
  }

  return (
    <>
      <>
        <Typography variant="h5" color="primary.contrastText" bgcolor="primary.main" paddingLeft="5%" paddingY={3}>
          Total Sales
        </Typography>
        <Box margin={deviceMargin}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <Paper sx={{ padding: 2 }}>
                <Grid item container spacing={2}>
                  <Grid item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        format="DD/MM/YYYY"
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        size="small"
                        label="Start Date"
                        value={start}
                        maxDate={end}
                        renderInput={params => (<TextField {...params} />)}
                        onChange={date => {
                          date.setHours(0, 0, 0, 0)
                          setStart(date)
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        format="DD/MM/YYYY"
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        size="small"
                        label="End Date"
                        value={end}
                        minDate={start}
                        renderInput={params => (<TextField {...params} />)}
                        onChange={date => {
                          date.setHours(23, 59, 59, 999)
                          setEnd(date)
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={3}>
                    <Button onClick={fetchReports}>
                      Search
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button color="success" onClick={exportReports}>
                      Export
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      label="Payments"
                      control={
                        <Checkbox
                          checked={report.payments}
                          onChange={() => setReport({ ...report, payments: !report.payments })}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      label="Credit Transactions"
                      control={
                        <Checkbox
                          checked={report.credit}
                          onChange={() => setReport({ ...report, credit: !report.credit })}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      label="Multi-Tax"
                      control={
                        <Checkbox
                          checked={report.multitax}
                          onChange={() => setReport({ ...report, multitax: !report.multitax })}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      label="Refunds"
                      control={
                        <Checkbox
                          disabled
                          checked={report.refunds}
                          onChange={() => setReport({ ...report, refunds: !report.refunds })}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      label="Sales by Department"
                      control={
                        <Checkbox
                          checked={report.departments}
                          onChange={() => setReport({ ...report, departments: !report.departments })}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      label="Sales by Category"
                      control={
                        <Checkbox
                          checked={report.categories}
                          onChange={() => setReport({ ...report, categories: !report.categories })}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      label="Discounts by Type"
                      control={
                        <Checkbox
                          checked={report.discounts}
                          onChange={() => setReport({ ...report, discounts: !report.discounts })}
                        />
                      }
                    />
                  </Grid>
                  {/* <Grid item xs={3}>
                    <FormControlLabel
                      label="Redemptions"
                      control={
                        <Checkbox
                          checked={report.redemptions}
                          onChange={() => setReport({ ...report, redemptions: !report.redemptions })}
                        />
                      }
                    />
                  </Grid> */}
                  <Grid item xs={3}>
                    <FormControlLabel
                      label="Voids"
                      control={
                        <Checkbox
                          checked={report.voids}
                          onChange={() => setReport({ ...report, voids: !report.voids })}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {report.payments &&
              <Grid item xs={12}>
                <Payments ref={paymentRef} mid={mid} pageSize={pageSize} />
              </Grid>}
            {report.credit &&
              <Grid item xs={12}>
                <CreditTransactions
                  ref={creditRef}
                  mid={mid}
                  pageSize={pageSize}
                  gatewayUsername={gatewayUsername}
                  gatewayPassword={gatewayPassword}
                  gatewayKey={gatewayKey}
                />
              </Grid>}
            {report.multitax &&
              <Grid item xs={12}>
                <MultiTax ref={multitaxRef} mid={mid} pageSize={pageSize} />
              </Grid>}
            {report.refunds &&
              <Grid item xs={12}>
                <Refunds
                  ref={refundRef}
                  mid={mid}
                  pageSize={pageSize}
                  gatewayUsername={gatewayUsername}
                  gatewayPassword={gatewayPassword}
                  gatewayKey={gatewayKey}
                />
              </Grid>}
            {report.departments &&
              <Grid item xs={12}>
                <DepartmentSales ref={departmentRef} mid={mid} pageSize={pageSize} />
              </Grid>}
            {report.categories &&
              <Grid item xs={12}>
                <CategorySales ref={categoryRef} mid={mid} pageSize={pageSize} />
              </Grid>}
            {report.discounts &&
              <Grid item xs={12}>
                <DiscountTypes ref={discountRef} mid={mid} pageSize={pageSize} />
              </Grid>}
            {/* {report.redemptions &&
              <Grid item xs={12}>
                <Redemptions ref={redemptionRef} mid={mid} pageSize={pageSize} />
              </Grid>} */}
            {report.voids &&
              <Grid item xs={12}>
                <Voids ref={voidRef} mid={mid} pageSize={pageSize} />
              </Grid>}
          </Grid>
        </Box>
      </>
    </>
  )
}