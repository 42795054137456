import React, { forwardRef, useImperativeHandle, useRef } from "react"
import { Grid, Paper, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import currency from "currency.js"
import { CSVLink } from "react-csv"
import { TOTAL_SALES_DEPARTMENT_CSV_HEADERS } from "../CSVHeaders"

const DepartmentSales = forwardRef((props, ref) => {
  // Hooks
  useImperativeHandle(ref, () => ({
    fetchReport: async (start, end) => {
      setLoading(true)
      const response = await fetch(`/api/reports/totalsales/departments?mid=${props.mid}&start=${start}&end=${end}`)
      const data = await response.json()
      setSales(data)
      setLoading(false)
    },
    exportReport: () => { exportRef.current.click() }
  }))

  // States
  const [loading, setLoading] = React.useState(false)
  const [sales, setSales] = React.useState([])
  
  // References
  const exportRef = useRef()

  // Functions
  function parseToTable() {
    return sales.map(sale => {
      return {
        ...sale,
        "Net Sales": currency(sale["Net Sales"], { fromCents: true }).format()
      }
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" fontWeight="bold" textAlign="center">
          Sales by Department
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <DataGrid
            loading={loading}
            pageSize={props.pageSize}
            rows={parseToTable()}
            columns={[
              { field: "Department", disableColumnMenu: true, minWidth: 100, flex: 1 },
              { field: "Net Sales", disableColumnMenu: true, minWidth: 100, flex: 1 },
              { field: "Discounts", disableColumnMenu: true, minWidth: 100, flex: 1 },
              { field: "Quantity", disableColumnMenu: true, minWidth: 100, flex: 1 }
            ]}
          />
        </Paper>
      </Grid>
      <CSVLink
        filename="Sales By Department"
        headers={TOTAL_SALES_DEPARTMENT_CSV_HEADERS}
        data={parseToTable()}
      >
        <span ref={exportRef} />
      </CSVLink>
    </Grid>
  )
})

export default DepartmentSales