import React, { forwardRef, useImperativeHandle, useRef } from "react"
import { Grid, Paper, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { CSVLink } from "react-csv"
import { TOTAL_SALES_PAYMENT_CSV_HEADERS } from "../CSVHeaders"

const Payments = forwardRef((props, ref) => {
  // Hooks
  useImperativeHandle(ref, () => ({
    fetchReport: async (start, end) => {
      setLoading(true)
      const response = await fetch(`/api/reports/totalsales/payments?mid=${props.mid}&start=${start}&end=${end}`)
      const data = await response.json()
      setPayments(data)
      setLoading(false)
    },
    exportReport: () => { exportRef.current.click() }
  }))

  // States
  const [loading, setLoading] = React.useState(false)
  const [payments, setPayments] = React.useState([])

  // References
  const exportRef = useRef()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" fontWeight="bold" textAlign="center">
          Payments
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <DataGrid
            loading={loading}
            pageSize={props.pageSize}
            rows={payments}
            columns={[
              { field: "Type", disableColumnMenu: true, minWidth: 100, flex: 1 },
              { field: "Total", disableColumnMenu: true, minWidth: 100, flex: 1 },
              { field: "Quantity", disableColumnMenu: true, minWidth: 100, flex: 1 }
            ]}
          />
        </Paper>
      </Grid>
      <CSVLink
        filename="Payments"
        headers={TOTAL_SALES_PAYMENT_CSV_HEADERS}
        data={payments}
      >
        <span ref={exportRef} />
      </CSVLink>
    </Grid>
  )
})

export default Payments