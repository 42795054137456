export default function TicketDetail(idTicketMaster, mid, lineItem) {
  this.credit = lineItem.price
  this.idTicketMaster = idTicketMaster
  this.isDiscountable = lineItem.isDiscountable
  this.itemId = lineItem.id
  this.itemName = lineItem.name
  this.mid = mid
  this.price = lineItem.cashPrice
  this.priceTax = lineItem.isTaxable ? Math.floor(lineItem.cashPrice * (lineItem.taxRate / 100)) : 0
  this.fee = Math.max(lineItem.price - lineItem.cashPrice, 0)
  this.feeTax = lineItem.isTaxable ? Math.floor(this.fee * (lineItem.taxRate / 100)) : 0
  this.qty = lineItem.quantity
  this.isGiftCard = lineItem.isGiftCard
  this.isVoided = false
  this.promptPrice = lineItem.promptPrice
  this.discountApplied = lineItem.discount

  // this.cashTotal = this.price + this.priceTax
  // this.creditTax = this.priceTax + this.feeTax
  // this.creditTotal = this.creditPrice + this.creditTax
  // this.isSent = false
}