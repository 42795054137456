import React, { forwardRef, useImperativeHandle, useRef } from "react"
import { Grid, Paper, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import useGateway from "../../../utils/useGateway"
import currency from "currency.js"
import { CSVLink } from "react-csv"
import { TOTAL_SALES_CREDIT_CSV_HEADERS } from "../CSVHeaders"

const CreditTransactions = forwardRef((props, ref) => {
  // Hooks
  const gateway = useGateway(props.mid, props.gatewayUsername, props.gatewayPassword, props.gatewayKey, "")
  useImperativeHandle(ref, () => ({
    fetchReport: async (start, end) => {
      setLoading(true)
      let transactions = await gateway.getTransactions(start.toLocaleDateString(), end.toLocaleDateString(), "", "", "")
      transactions = transactions.filter(transaction => { return transaction.type !== "Void" })
      console.log(transactions)
      setTransactions(transactions)
      setLoading(false)
    },
    exportReport: () => { exportRef.current.click() }
  }))

  // States
  const [loading, setLoading] = React.useState(false)
  const [transactions, setTransactions] = React.useState([])

  // References
  const exportRef = useRef()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" fontWeight="bold" textAlign="center">
          Credit Transactions
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <DataGrid
            loading={loading}
            pageSize={props.pageSize}
            rows={transactions.map(transaction => {
              return {
                id: transaction.transactionID,
                Name: transaction.cardholder,
                "Transaction ID": transaction.transactionID,
                "Card Number": transaction.cardNumber,
                Tip: "$0.00",
                Total: currency(transaction.total).format()
              }
            })}
            columns={[
              { field: "Name", disableColumnMenu: true, minWidth: 100, flex: 1 },
              { field: "Transaction ID", disableColumnMenu: true, minWidth: 100, flex: 1 },
              { field: "Card Number", disableColumnMenu: true, minWidth: 100, flex: 1 },
              { field: "Tip", disableColumnMenu: true, minWidth: 100, flex: 1 },
              { field: "Total", disableColumnMenu: true, minWidth: 100, flex: 1 },
            ]}
          />
        </Paper>
      </Grid>
      <CSVLink
        filename="Credit Transactions"
        headers={TOTAL_SALES_CREDIT_CSV_HEADERS}
        data={transactions}
      >
        <span ref={exportRef} />
      </CSVLink>
    </Grid>
  )
})

export default CreditTransactions